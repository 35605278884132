import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

export default function Education() {
  const [education, setEducation] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch("https://api.rbonweb.in/api/education", requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setEducation(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);
  const listItems = education.map((education) => (
    <Row key={education.course} className="mb-3">
      <Col md={7}>
        <p className="h5 text-primary">
          {education.course} | {education.degree}{" "}
        </p>
        <p className="h6">College/Institute : {education.college}</p>
        <p className="h6">Grades : {education.result}/10</p>
      </Col>
      <Col md={5} className="text-right">
        <p className="h6 font-weight-bold text-primary text-uppercase mb-3 r-subtitle">
          <FontAwesomeIcon icon={faCalendarAlt} /> {education.duration}
        </p>
      </Col>
    </Row>
  ));
  return listItems;
}
