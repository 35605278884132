import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          background: "#008080",
          zIndex: 1,
        }}
      >
        <Loader type="Rings" color="#c87137" height="300" width="300" />
      </div>
    )
  );
};

ReactDOM.render(
  <React.Fragment>
    <LoadingIndicator />
    <App />
  </React.Fragment>,
  document.getElementById("root")
);
