import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";

export default function Characterstic() {
  const [characteristic, setCharacteristic] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch("https://api.rbonweb.in/api/characteristics", requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setCharacteristic(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);
  const listItems = characteristic.map((characterstic) => (
    <Col key={characterstic.title} className="mb-2" sm={6} xs={6} md={3}>
      <p className="p-1 btn font-weight-bolder btn-outline-primary rb-badge w-100">
        {characterstic.title}
      </p>
    </Col>
  ));
  return listItems;
}
