import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

export default function Social() {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch("https://api.rbonweb.in/api/skills", requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setSkills(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const listItems = (
    <Row>
      <SkillModel title="Language" data={skills} />
      <SkillModel title="Libraries/Frameworks" data={skills} />
      <SkillModel title="Ongoing" data={skills} />
    </Row>
  );
  return listItems;
}

function SkillModel(props) {
  const filteresItems = props.data.filter((item) => {
    return item.category === props.title;
  });

  const listItems = filteresItems.map((skill) => (
    <p key={skill.skill}>{skill.skill}</p>
  ));

  return (
    <Col className="mb-2" sm={12} xs={12} md={4}>
      <p className="text-primary font-weight-bolder r-subtitle text-uppercase">
        {props.title}
      </p>
      {listItems}
    </Col>
  );
}
