import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

export default function Testimonial() {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch("https://api.rbonweb.in/api/testimonials", requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setTestimonials(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const listItems = testimonials.map((testimonial) => (
    <div key={testimonial.name} className="row mb-5">
      <div className="col-md-2 text-center">
        <img
          src={"data:image/png;base64," + testimonial.image}
          className="rounded-circle img-fluid shadow"
          width="100"
          alt={testimonial.name}
        />
      </div>
      <div className="col-md-10">
        <h4 className="font-weight-bold mt-4">
          {testimonial.name}
          <span className="small">({testimonial.post})</span>
        </h4>
        <p className="font-weight-normal">
          <FontAwesomeIcon className="pr-2" icon={faQuoteLeft} />{" "}
          {testimonial.review}
        </p>
      </div>
    </div>
  ));
  // return listItems;
  return (
    <div className="container text-left mt-5 inout-animation">{listItems}</div>
  );
}
