import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";

export default function Social() {
  const [socials, setSocials] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    trackPromise(
      fetch("https://api.rbonweb.in/api/socials", requestOptions)
        .then((res) => res.json())
        .then(
          (result) => {
            setSocials(result);
          },
          (error) => {
            console.log(error);
          }
        )
    );
  }, []);

  const SocialItems = socials.map((social) => (
    <li key={social.handle} className="nav-item">
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="nav-link"
        href={social.url}
      >
        {social.handle}
      </a>
    </li>
  ));
  return SocialItems;
}
