import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Badge from "react-bootstrap/Badge";

export default function Work() {
  const [works, setWorks] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch("https://api.rbonweb.in/api/works", requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setWorks(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  let i = 1;
  const listItems = works.map((work) => (
    <Col
      key={i++}
      md={6}
      className={
        i % 2 === 0 ? "text-left l-work mt-5" : "text-right r-work mt-5"
      }
    >
      <p className="h5 text-primary">
        {work.title} (
        <a href={work.url}>
          {work.link}{" "}
          <span className="small">
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </span>
        </a>
        )
      </p>
      <p className="h6">{work.description}</p>
      {JSON.parse(work.tags).map((tag) => (
        <Badge key={tag} className="me-2" variant="primary">
          {tag}
        </Badge>
      ))}
    </Col>
  ));
  return (
    <div className="container inout-animation">
      <Row>{listItems}</Row>
    </div>
  );
}
