import React, { useState } from "react";
import rb from "./img/main/avtar.jpeg";
import wave from "./img/emojis/wave.png";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import "./App.scss";
import Personalinfo from "./parts/PersonalInfo";
import Experience from "./parts/Experience";
import Characterstic from "./parts/Characterstic";
import Skill from "./parts/Skill";
import Social from "./parts/Social";
import Education from "./parts/Education";
import Cookies from "universal-cookie";
import Work from "./parts/Work";
import Testimonials from "./parts/Testimonials";
// import Achievement from "./parts/Achievement";

const scrollToRef = (ref) => window.scrollTo(0, window.innerHeight);

export default function App() {
  const executeScroll = () => scrollToRef();
  const [status, setStatus] = useState(localStorage.getItem("status"));
  const [isChecked, setIsChecked] = useState(false);

  const NightSwitch = (props) => {
    if (status === "") {
      setIsChecked(false);
      document.body.style.backgroundColor = "#ffffff";
      document.body.style.color = "black";
    } else {
      setIsChecked(true);
      document.body.style.backgroundColor = "#131417";
      document.body.style.color = "white";
    }

    const handlechange = () => {
      if (status === "") {
        setStatus("night");
        localStorage.setItem("status", "night");
        document.body.style.backgroundColor = "#131417";
        document.body.style.color = "#FFFFFF";
      } else {
        setStatus("");
        localStorage.setItem("status", "");
        document.body.style.backgroundColor = "#ffffff";
      }
    };

    return (
      <div style={{ position: "absolute", top: "5%", right: "5%", zIndex: 0 }}>
        <div
          className="sun"
          style={{
            display: "inline-flex",
            verticalAlign: "top",
            paddingTop: "5px",
            paddingRight: "5px",
          }}
        >
          <FontAwesomeIcon icon={faSun} />
        </div>
        <label className="switch" htmlFor="checkbox">
          <input
            type="checkbox"
            id="checkbox"
            onChange={(event) => {
              handlechange();
              setIsChecked(event.currentTarget.checked);
            }}
            checked={isChecked}
          />
          <div className="slider round"></div>
        </label>
        <div
          className="moon"
          style={{
            display: "inline-flex",
            verticalAlign: "top",
            paddingTop: "5px",
            paddingLeft: "5px",
          }}
        >
          <FontAwesomeIcon icon={faMoon} />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <NightSwitch />
      <main className={"container " + status}>
        <div className="d-flex flex-column justify-content-center vh-100 pt-md-5">
          <div className="text-center">
            <img
              className="img-fluid rounded-circle shake"
              width="150"
              srcSet={rb}
              alt="Rohit Bhadani"
            />
            <p className="h1 mt-3">
              <img
                className="shake"
                srcSet={wave}
                width={35}
                alt="Wave Emoji"
              />{" "}
              Hi! there,
            </p>
            <p className="h1 font-weight-bold mt-md-5">
              <span className="h1">I'm </span>Rohit Bhadani,
            </p>
            <p className="h1">Full Stack Developer | React, Laravel, NodeJS</p>
          </div>
          <ul className="nav nav-justified mt-md-4">
            <Social />
          </ul>
          <hr className="rb-hr" />
          <div onClick={executeScroll} className="arrow"></div>
        </div>
        <ControlledTabs />
        <p className="text-center mt-4">
          &copy; {new Date().getFullYear()} | Rohit Bhadani | @rbonweb
        </p>
      </main>
    </React.Fragment>
  );
}

function ControlledTabs() {
  const cookies = new Cookies();
  const [key, setKey] = useState(cookies.get("tabs"));
  return (
    <section className="min-vh-100">
      <Tabs
        variant="tabs"
        justify
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
          cookies.set("tabs", k, { path: "/" });
        }}
        id="controlled-tab-example"
      >
        <Tab tabClassName="m-3 w-90" eventKey="intro" title="Intro">
          <div className="inout-animation">
            <Row className="mt-5">
              <Col md={3} className="text-center text-md-right">
                <p className="h5 font-weight-bold text-primary text-uppercase mb-3 l-title">
                  Background
                </p>
              </Col>
              <Col md={9} className="text-left text-md-left">
                <p>
                  Hello, I’m{" "}
                  <strong className="text-primary">Rohit Bhadani</strong>! I am
                  a Full Stack Developer with the skills of React, Laravel,
                  NodeJS. I have been developing web and mobile apps since 3+
                  years and coupled with analytical and technical skills in
                  gathering system requirements, logical data &amp; process
                  modeling, use case modeling, and workflow modeling. :)
                </p>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={3} className="text-center text-md-right">
                <p className="h5 font-weight-bold text-primary text-uppercase mb-3 l-title">
                  Personal information
                </p>
              </Col>
              <Col md={9}>
                <Row>
                  <Personalinfo
                    title="Full Name"
                    titledata="Rohit N. Bhadani"
                  />
                  <Personalinfo title="D.O.B." titledata="20 May 1997" />
                  <Personalinfo
                    title="ADDRESS"
                    titledata="Modasa, Gujarat, India"
                  />
                  <Personalinfo title="Mobile" titledata="+91 9426732822" />
                  <Personalinfo
                    title="Email"
                    titledata="bhadanirohit1@gmail.com"
                  />
                  <Personalinfo title="Freelance" titledata="Availiable" />
                </Row>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={3} className="text-center text-md-right">
                <p className="h5 font-weight-bold text-primary text-uppercase mb-3 l-title">
                  Development Skills
                </p>
              </Col>
              <Col md={9}>
                <Skill />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={3} className="text-center text-md-right">
                <p className="h5 font-weight-bold text-primary text-uppercase mb-3 l-title">
                  Personal characteristics
                </p>
              </Col>
              <Col md={9}>
                <Row>
                  <Characterstic />
                </Row>
              </Col>
            </Row>
          </div>
        </Tab>
        <Tab
          tabClassName="m-3 w-90"
          eventKey="experience"
          title={
            <span>
              E<sup>2</sup>
            </span>
          }
        >
          <div className="inout-animation">
            <Row className="mt-5">
              <Col md={3} className="text-center text-md-right">
                <p className="h5 font-weight-bold text-primary text-uppercase mb-3 l-title">
                  Experience
                </p>
              </Col>
              <Col md={9}>
                <Experience />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={3} className="text-center text-md-right">
                <p className="h5 font-weight-bold text-primary text-uppercase mb-3 l-title">
                  Education
                </p>
              </Col>
              <Col md={9}>
                <Education />
              </Col>
            </Row>
          </div>
        </Tab>
        <Tab tabClassName="m-3 w-90" eventKey="works" title="Works">
          <Work />
        </Tab>
        {/* <Tab tabClassName="m-3 w-90" eventKey="certification" title="Certification">
          <Achievement />
        </Tab> */}
        <Tab tabClassName="m-3 w-90" eventKey="testimonials" title="Testimonials">
          <div className="inout-animation">
            <div className="mt-5">
              <Testimonials />
            </div>
          </div>
        </Tab>
      </Tabs>
    </section>
  );
}
