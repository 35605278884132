import React, { Component } from "react";
import { Col } from "react-bootstrap";

export default class PersonalInfo extends Component {
  render() {
    return (
      <Col className="mb-2" sm={6} xs={6} md={4}>
        <p className="text-primary font-weight-bolder r-subtitle">
          {this.props.title} :{" "}
        </p>
        <p>{this.props.titledata}</p>
      </Col>
    );
  }
}
