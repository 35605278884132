import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";

export default function Experience() {
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch("https://api.rbonweb.in/api/experiences", requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setExperiences(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const listItems = experiences.map((experience) => (
    <Row key={experience.firm} className="mb-3">
      <Col md={7}>
        <p className="h5 text-primary">
          {experience.firm} (
          <a href={experience.url}>
            {experience.website}{" "}
            <span className="small">
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </span>
          </a>
          )
        </p>
        <p className="h6">Position : {experience.position}</p>
      </Col>
      <Col md={5} className="text-right">
        <p className="h6 font-weight-bold text-primary text-uppercase mb-3 r-subtitle">
          <FontAwesomeIcon icon={faCalendarAlt} /> {experience.period}
        </p>
      </Col>
    </Row>
  ));
  return listItems;
}
